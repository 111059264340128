
import Images from "../images/images.js"
import Ticker from "react-ticker";
import styles from "./themes.module.css"
import React from "react";
import PageVisibility from 'react-page-visibility'


const {romance, fear, drama, adventure, mystery} = Images;


let pageIsVisible = true;
let setPageIsVisible = true;

class InfiniteScrollImages extends React.Component {

    render() {
        return (
            <Ticker speed="2">
                {({ index }) => (
                    <div className={styles.scenarioContainer}>
                      <img src={mystery} alt="mystery" />
                      <img src={fear} alt="fear" />
                      <img src={adventure} alt="adventure" />
                      <img src={drama} alt="drama" />
                      <img src={romance} alt="romance" />
                    </div>
                )}
            </Ticker>
        )
    }
}

export default InfiniteScrollImages